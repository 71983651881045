import React, {useEffect, useState} from 'react';
import s from './ManagerPage.module.css'
import config from "../../../config";
import GameControlState from "../../../State/GameControlState/GameControlState";
import Loader from "../../Static/Loader/Loader";
import TimerComponent from "../TimerComponent/TimerComponent";

const ManagerPage = ({token, game}) => {
    const [isLoad, setIsLoad] = useState(false)
    const [gameData, setGameData] = useState(null)
    const [scores, setScores] = useState(null)
    const [time, setTime] = useState(-1)
    const [teamsMap, setTeamsMap] = useState([])
    // const [keyWords, setKeyWords] = useState([])
    const init = ()=>{
        const ws = new WebSocket(config.SERVER_SOCKET)
        ws.onopen = function () {
            console.log("подключился", game.id, token);
            ws.send(JSON.stringify({
                action: 'login',
                type: 'manager',
                game:game.id,
                token
            }));
        };
        ws.onclose = ()=>{
            init()
        }
        ws.onmessage = function (message) {
            const data = JSON.parse(message.data)
            if(data.action!=='time')
                console.log("Message", data);
            switch (data.action) {
                case 'gameData':
                    setGameData(data)
                    break
                case 'scoreTeams':
                    setScores(data.scores)
                    break
                case 'time':
                    setTime(data.time)
                    break
                // case 'keyWords':
                //     setKeyWords(data.words)
                //     break
                case 'teamsMap':
                    setTeamsMap(data.tasks)
                    break

            }


        };
        // setWs(ws)
        setIsLoad(true)
        // setGameControlState(new GameControlState(ws))
    }
    useEffect(()=>{
        init()
    },[])
    if(isLoad)
        return (
            <div>
                {gameData && gameData.isStart?
                    <div className={s.status_game_start}>Игра началась</div>:
                    <div className={s.status_game_stop}>Игра не запущена</div>
                }
                {gameData && gameData.isFinish?
                    <div className={s.status_game_start}>Игра завершена</div>:
                   <></>
                }
                {gameData && gameData.game && gameData.game.gameName?
                    <div>Название игры: {gameData.game.gameName}</div>:
                    <></>}
                {gameData?<div>Текущий раунд: {gameData.currentRound+1}</div>:<></>}
                {time>=0?<TimerComponent time={time} />:<></>}
                {scores?<div className={s.wrapper_score}>
                    <div className={s.title_score}>Счет команд</div>
                    <table className={s.table_team_map}>
                        <tbody>
                    <tr>
                        <th></th>
                        <th>команда</th>
                        <th>Куплен</th>
                        <th>счет</th>
                    </tr>
                    {scores.map((score,index)=>{
                        return(
                            <tr key={'row_team_score'+index}>
                                <td>{index+1}</td>
                                <td>{score.team}</td>
                                {score.isBuy? <td className={s.is_buy_yes}>да</td>: <td className={s.is_buy_no}>нет</td>}
                                <td>{score.score}</td>
                            </tr>
                        )
                    })}
                        </tbody>
                    </table>
                </div>:<></>}
                {teamsMap && teamsMap.length>0?
                    <div  className={s.wrapper_score}>
                        <div className={s.title_score}>Задания команд</div>

                    <table  className={s.table_team_tasks}>
                        <tbody>
                        <tr>
                            <th>#</th>
                            <th>Команда</th>
                            <th>Задание</th>
                        </tr>

                        {teamsMap.map((team,index)=>{
                            console.log('team', team)
                        return(
                            <tr key={'row_team_map'+index}>
                                <td>{index+1}</td>
                                <td>{team.team.stringName}</td>
                                <td>
                                        {team.tasks && team.tasks.length>0?
                                            <table className={s.table_team_task}>
                                                <tbody>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Название</th>
                                                    <th>Локация</th>
                                                    <th>Тип</th>
                                                </tr>
                                                {team.tasks.map((task, i)=>{
                                                    return(
                                                        <tr key={'tasks_row_'+index+i}>
                                                            <td>{i+1}</td>
                                                            <td>{task.title}</td>
                                                            <td>{task.location}</td>
                                                            <td>{task.type}</td>
                                                        </tr>
                                                    )
                                                })}
                                                </tbody>
                                            </table>:''
                                        }

                                </td>
                        </tr>
                        )
                })}
                        </tbody>
                    </table>

                    </div>:<></>
                }
                {/*{keyWords.length}*/}
                {/*{keyWords.length>0?<div>*/}
                {/*    <table>*/}
                {/*        <tbody>*/}
                {/*        <tr>*/}
                {/*            <th>#</th>*/}
                {/*            <th>команда</th>*/}
                {/*            <th>слово</th>*/}
                {/*            <th>тип</th>*/}
                {/*            <th>цена</th>*/}
                {/*        </tr>*/}
                {/*        {keyWords.map((key, index)=>{*/}
                {/*            return(*/}
                {/*                <tr key={'row_key_word_'+index}>*/}
                {/*                    <td>{index+1}</td>*/}
                {/*                    <td>{key.team}</td>*/}
                {/*                    <td>{key.word}</td>*/}
                {/*                    <td>{key.type}</td>*/}
                {/*                    <td>{key.price}</td>*/}
                {/*                </tr>*/}
                {/*            )*/}
                {/*        })}*/}
                {/*        </tbody>*/}
                {/*    </table>*/}
                {/*</div>:<></>}*/}
            </div>
        )
    else
        return (
            <Loader/>
        )
};

export default ManagerPage;