import React, {useEffect, useState} from 'react';
import s from './GameAdministrator.module.css'
import GameState from "../../State/GameState/GameState";
import Loader from "../Static/Loader/Loader";
import GameBox from "./GameBox/GameBox";
import ControlGame from "./ControlGame/ControlGame";
import ManagerPage from "./ManagerPage/ManagerPage";

const GameAdministrator = ({token, role}) => {

    const [isLoad, setIsLoad] = useState(false)
    const [games,setGames] = useState([])
    const [activeGame, setActiveGame] = useState('home')

    const init = async ()=>{
        setIsLoad(false)
        const resultGames = await GameState.getGames()
        setGames(resultGames)
        setIsLoad(true)
    }

    useEffect(()=>{
        init()
    },[])
    return isLoad?(
        <>
            {activeGame === 'home'?
                <div>
                    <div>{games.map((game, i)=>(
                        <GameBox key={'admin_game_game_box_'+i} goToGame={setActiveGame} game={game}/>
                        ))}</div>
                </div>
                :
                <div>
                    {role==='admin'?
                        <ControlGame token={token} goToChoiceGame={setActiveGame} game={games.find(game=>game.id === activeGame)}/>:
                        <ManagerPage token={token} game={games.find(game=>game.id === activeGame)}/>}
                </div>
            }
        </>
    ):(
        <Loader/>
    )
};

export default GameAdministrator;