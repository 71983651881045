import React from 'react';
import s from './NavBar.module.css'
import vg_logo from '../resource/vg_logo.JPG'

const NavBar = ({role, setPoint}) => {
    return (
        <div className={s.wrapper} onClick={()=>setPoint('home')}>
            <div className={s.logo}>Игровой сервер</div>
            <div className={s.point}>{role}</div>
        </div>
    );
};

export default NavBar;