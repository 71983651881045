import React from 'react';
import s from './AddOrUpdateDurationTask.module.css'

const AddOrUpdateDurationTask = ({task, changeData, games, changeChanges, addRowChanges, delRowChanges}) => {
    return (
        <div>
            {task.id === 'new' ? <div>Создание нового дополнительного задания</div> : <div>Изменить задание</div>}
            <div>Название задания</div>
            <input type="text"
                   className={s.input_box}
                   placeholder={'Название'}
                   value={task.title}
                   onChange={(e) => {
                       changeData('title', e.target.value)
                   }}/>

            <div>тип задания</div>
            <input type="text"
                   className={s.input_box}
                   placeholder={'Тип'}
                   value={task.type}
                   onChange={(e) => {
                       changeData('type', e.target.value)
                   }}/>

            <div>Локация</div>
            <input type="text"
                   className={s.input_box}
                   placeholder={'Локация'}
                   value={task.location}
                   onChange={(e) => {
                       changeData('location', e.target.value)
                   }}/>

            <div>Ответы и цены</div>
            <table>
                <tbody>
                <tr>
                    <td>Текст ответа</td>
                    <td>Цена ответа</td>
                    <td>Удалить</td>
                </tr>
                {task.changes.map((change, index) => {
                    console.log('change',change)
                    return (
                        <tr key={'duration_task_' + index}>
                            <td>
                                <textarea
                                    rows={4}
                                    className={s.input_box}
                                    placeholder={'текст задания'}
                                    value={change.text}
                                    onChange={(e) => {
                                        changeChanges(index, 'text', e.target.value)
                                    }}/>
                            </td>
                            <td>
                                <table><tbody>
                                <tr>
                                    <td>{"Ресурсы"}</td>
                                    <td>
                                        <input
                                            type="text"
                                            className={s.input_box}
                                            placeholder={'ресурсы'}
                                            value={change.prices.resource}
                                            onChange={(e) => {
                                                changeChanges(index, 'prices', Number(e.target.value),'resource' )
                                            }}/>
                                    </td>
                                </tr>
                                {games.find(g=>g.id === task.game).additionalResource?games.find(g=>g.id === task.game).additionalResource.map((additional, i)=>{
                                    return(
                                        <tr key={'additional_resource'+index+i}>
                                            <td>{additional.stringName}</td>
                                            <td>
                                                <input
                                                    type="text"
                                                    className={s.input_box}
                                                    placeholder={'Локация'}
                                                    value={change.prices[additional.name]}
                                                    onChange={(e) => {
                                                        changeChanges(index, 'prices', Number(e.target.value),additional.name )
                                                    }}/>
                                            </td>
                                        </tr>
                                    )
                                    }
                                ):<></>}
                                </tbody></table>

                            </td>
                            <td>
                                <button onClick={()=>delRowChanges(index)}>-</button>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
            <div onClick={addRowChanges} className="btn">Добавить</div>


            <div>Текст задания</div>
            <textarea
                   className={s.input_box}
                   placeholder={'Текст задания'}
                   value={task.text}
                   onChange={(e) => {
                       changeData('text', e.target.value)
                   }}/>

            <div>Игра</div>
            <select value={task.game} onChange={(e) => {
                changeData('game', e.target.value)
            }}>{games.map((game, index) => (
                <option key={'game_list_redact_user_' + index} value={game.id}>{game.gameName}</option>
            ))}

            </select>

            {/*<input type="text"*/}
            {/*       className={s.input_box}*/}
            {/*       placeholder={'Игра'}*/}
            {/*       value={user.game}*/}
            {/*       onChange={(e)=>{*/}
            {/*           changeData('game', e.target.value)*/}
            {/*       }}/>*/}

            <div>Описание</div>
            <input type="text"
                   className={s.input_box}
                   placeholder={'Описание'}
                   value={task.description}
                   onChange={(e) => {
                       changeData('description', e.target.value)
                   }}/>

            <input type={'checkbox'}
                   className={s.check_box}
                   checked={task.isActive}
                   onChange={(e) => {
                       changeData('isActive', e.target.checked)
                   }}/> <span>Игра активна?</span>

        </div>
    );
};

export default AddOrUpdateDurationTask;