import React, {useEffect, useState} from 'react';
import s from './MapConstructor.module.css'
import GameState from "../../../../State/GameState/GameState";
import UserState from "../../../../State/UserState/UserState";
import MapState from "../../../../State/MapState/MapState";
import Loader from "../../../Static/Loader/Loader";
import MapCard from "./MapCard/MapCard";
import TaskState from "../../../../State/TaskState/TaskState";

const MapConstructor = () => {
    const [games, setGames] = useState([])
    const [users, setUsers] = useState([])
    const [maps, setMaps] = useState([])
    const [isLoad, setIsLoad] = useState(false)
    const [activeGame, setActiveGame] = useState('none')
    const [activeUser, setActiveUser] = useState('none')
    const [redactMap, setRedactMap] = useState(null)
    const [tasks, setTasks] = useState([])

    const choiceMapByActiveUser = (game, userId, mapsList)=>{
        const activeMap = mapsList.find(m=>m.team === userId)
        if (activeMap)
            setRedactMap(activeMap)
        else{

            const newMap = MapState.getEmpty()
            newMap.id = 'new'
            newMap.team = userId
            newMap.game = game.id
            newMap.tasks = []
            for (let i =0; i<game.countRound; i++){
                newMap.tasks[i] = ['none']
            }
            setRedactMap(newMap)
        }

    }

    const saveMap = async()=>{
        let res
        if (redactMap.id === 'new')
             res = await MapState.addMap(redactMap)
        else
             res = await MapState.updateMap(redactMap)
        return !!res;
    }

    const delRowTask = (index)=>{
        if (window.confirm('Удалить строку заданий?')){
            const currentMap = {...redactMap}
            currentMap.tasks = currentMap.tasks.filter((map, i)=>i !== index)
            setRedactMap(currentMap)

        }
    }

    const addRowTask = () =>{
        const currentMap = {...redactMap}
        currentMap.tasks.push(['none'])
        setRedactMap(currentMap)
    }

    const addField = (index, type)=>{
        if (type>0) {
            const rm = {...redactMap}
            rm.tasks[index].push('none')
            setRedactMap(rm)
        }
        else{
            const rm = {...redactMap}
            const newTasks = []
            for(let i = 0; i<rm.tasks[index].length-1;i++)
                newTasks.push(rm.tasks[index][i])
            rm.tasks[index] = newTasks

            setRedactMap(rm)
        }
    }

    const redactUserMap = (indexMap, indexTask, value)=>{
        redactMap.tasks[indexMap][indexTask] = value
        setRedactMap({...redactMap})
    }

    const getUserByGameId = async (gameId)=>{
        let usersFromServer = await UserState.getUsersByGameId(gameId)
        usersFromServer = usersFromServer.filter(user=>user.role === 'player')
        let user
        if(usersFromServer.length>0){
            user = usersFromServer[0].id
        }else{
            user = 'none'
        }
        setUsers(usersFromServer)
        setActiveUser(user)
        return user
    }

    const getMapsByGameId = async (game, userId)=>{
        const mapsList = await MapState.getMapsByGameId(game.id)
        setMaps(mapsList)
        // console.log(mapsList)
        // setMaps(mapsList)
        choiceMapByActiveUser(game,userId,mapsList)

        return true
    }

    const getTasksByGameId = async (gameId)=>{
        const tasksList = await TaskState.getTasksByGameId(gameId)
        setTasks(tasksList)
    }

    const init = async()=>{
        const resGames = await GameState.getGames()
        setGames(resGames)

        if (resGames && resGames.length>0) {
            setActiveGame(resGames[0])
            const userId = await getUserByGameId(resGames[0].id)
            await getMapsByGameId(resGames[0], userId)
            await getTasksByGameId(resGames[0].id)
        }

        setIsLoad(true)
    }

    useEffect(()=>{
        init()
    },[])
    return isLoad?(
        <div>

            <div>Выберите игру</div>
            <select defaultValue={activeGame} onChange={async(e)=>{
                setIsLoad(false)
                setActiveGame(e.target.value)
                const userList = await getUserByGameId(e.target.value)

                await getMapsByGameId( games.find(g=>g.id === e.target.value),userList)
                setIsLoad(true)
            }}>
                {games.map((game,index)=>{
                    return(
                        <option key={'map_constructor_games'+index} value={game.id}>{game.gameName}</option>
                    )
                })}
            </select>

            <div>Выберите команду</div>
            {activeUser!=='none'?
                <div>
                    <select defaultValue={activeUser} onChange={(e)=>{
                        choiceMapByActiveUser(activeGame, e.target.value, [...maps])
                    }}>
                        {users.map((user,index)=>{
                            return(
                                <option key={'map_constructor_users'+index} value={user.id}>{user.stringName}</option>
                            )
                        })}
                    </select>

                    <div>
                        <MapCard map={{...redactMap}} tasks={tasks} users={users}
                                 delRowTask={delRowTask}
                                 teamName={users.find(
                                     user=>user.id===activeUser)?
                                     users.find(user=>user.id===activeUser).stringName:'none'}
                                 addField={addField}
                                 redactUserMap={redactUserMap}
                                 game={activeGame}/>
                                 <div onClick={()=> {
                                     addRowTask()
                                 }} className={s.btn_add_row}>Добавить строку</div>
                                 <div onClick={async (e)=>{
                                     e.target.innerText = 'Сохраняю...'
                                     if(await saveMap()){
                                         e.target.innerText = 'Сохранено'
                                     }else
                                         e.target.innerText = 'Ошибка'
                                     setTimeout(()=>{
                                         e.target.innerText = 'Сохранить'
                                     },1200)
                                 }} className={'btn'}>Сохранить</div>
                    </div>
                </div>:<div>Нет команд</div>
            }



        </div>
    ):(
        <Loader/>
    )
};

export default MapConstructor;