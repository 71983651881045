
class KeysClass {
    id
    game
    correctKey
    incorrectKey
    drawKey
    mark
    price
    isActive

    constructor(gameId) {
        this.id = 'new'
        this.game = gameId
        this.correctKey = [{key:'', mark:''}]
        this.incorrectKey = [{key:'', mark:''}]
        this.drawKey = [{key:'', mark:''}]
        this.mark = ''
        this.price = 0
        this.isActive = true
    }
}

export default KeysClass