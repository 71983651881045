import React from 'react';
import s from './ChoiceComponent.module.css'

const ChoiceComponent = ({setPoint, point, role}) => {
    return (
        <div className={s.wrapper}>
            {role==='admin'?<div onClick={()=>{
                setPoint('constructor')
            }} className={s.point}>Конструктор</div>:<></>}
            <div onClick={()=>{
                setPoint('game')
            }} className={s.point}>Игры</div>
        </div>
    );
};

export default ChoiceComponent;