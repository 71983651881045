import React from 'react';
import s from './GameBox.module.css'

const GameBox = ({game, goToGame}) => {
    return (
        <div className={s.wrapper}>
            GameBox
            <div>{game.gameName}</div>
            <div onClick={()=>{goToGame(game.id)}} className={s.btn_continue}>Перейти</div>
        </div>
    );
};

export default GameBox;