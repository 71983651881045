import React, {useEffect, useState} from 'react';
import s from './ControlGame.module.css'
import config from "../../../config";
import Loader from "../../Static/Loader/Loader";
import GameControlState from "../../../State/GameControlState/GameControlState";
import ErrorTableComponent from "../errorTableComponent/errorTableComponent";
import TimerComponent from "../TimerComponent/TimerComponent";
import ScoresComponent from "../ScoresComponent/ScoresComponent";
import TeamsMapComponent from "../TeamsMapComponent/TeamsMapComponent";

const ControlGame = ({game, token, goToChoiceGame}) => {
    const [isLoad, setIsLoad] = useState(false)
    const [gameData, setGameData] = useState({})
    const [time, setTime] = useState(-1)
    const [ws, setWs] = useState({})
    const [gameControlState, setGameControlState] = useState({})
    const [teamsMap, setTeamsMap] = useState([])
    const [errorMessage, setErrorMessage] = useState([])
    const [isVisibleEvent, setIsVisibleEvent] = useState(false)
    const [event, setEvent] = useState({})
    const [eventList, setEventList] = useState([])
    const [scores, setScores] = useState(null)
    const [keyWords, setKeyWords] = useState([])

    const init = ()=>{
        const ws = new WebSocket(config.SERVER_SOCKET)
        ws.onopen = function () {
            ws.send(JSON.stringify({
                action: 'login',
                type: 'admin',
                game:game.id,
                token
            }));
        };
        ws.onclose = ()=>{
            init()
        }
        ws.onmessage = function (message) {
            const data = JSON.parse(message.data)
            if(data.action!=='time')
                console.log("Message", data);
            switch (data.action) {
                case 'errorReport':
                    const errList = [...errorMessage]
                    errList.push(data.message)
                    setErrorMessage(errList)
                    break
                case 'gameData':
                    setGameData(data)
                    break
                case 'time':
                    setTime(data.time)
                    break
                case 'teamsMap':
                    setTeamsMap(data.tasks)
                    break
                case 'event':
                    setEventList(data.events)
                    break
                case 'keyWords':
                    setKeyWords(data.words)
                    break
                case 'scoreTeams':
                    setScores(data.scores)
            }


        };
        setWs(ws)
        setIsLoad(true)
        setGameControlState(new GameControlState(ws))
    }
    useEffect(()=>{
        init()
    },[])
    const changeEvent = (fieldName, value)=>{
        const newEvent = {...event}
        newEvent[fieldName] = value
        setEvent(newEvent)
    }
    const addEvent = ()=>{
        if(ws){
            ws.send(JSON.stringify({
                action: 'addEvent',
                event
            }));
        }
    }
    const delEvent=(id)=>{
        if(ws){
            ws.send(JSON.stringify({
                action: 'delEvent',
                id
            }));
        }
    }
    return isLoad?(
        <div>
            <div className={s.btn_back} onClick={()=>goToChoiceGame('home')}>{'<<< Вернуться'}</div>
            <div>{game.gameName}</div>
            {time>=0?<TimerComponent time={time}/> :''}
            <div>{gameData.isStart?
                <>
                    <div className={s.round}>Раунд: {gameData.currentRound+1}</div>
                    <div className={s.game_status_start}>Игра запущена</div>
                    {gameData.isFinish?<div className={s.game_finish}>Игра завершена</div>:<></>}
                    <div onClick={()=>{
                        gameControlState.sendStop()
                    }} className={'btn'}>Остановить</div>

                    {gameData.isEvent?
                    <div>
                        <div>Происходит игровое событие</div>
                            <div>{teamsMap.text?teamsMap.text:''}</div>
                            <div>{teamsMap.price?teamsMap.price:''}</div>
                    </div>:<></>
                    }

                    <TeamsMapComponent isEvent={gameData.isEvent} teamsMap={teamsMap}/>
                    <ScoresComponent scores={scores}/>

                </>
                :
                <>
                    <div className={s.game_status_stop}>Игра не запущена</div>
                    <div onClick={()=>{
                        gameControlState.sendStart()
                    }} className={'btn'}>Запустить</div>
                    <div onClick={()=>{
                        gameControlState.sendRefresh()
                    }} className={'btn'}>Сброс</div>
                </>
               }</div>
            {

            }
            <div onClick={()=> {
                setIsVisibleEvent(!isVisibleEvent)
                setEvent({price:0, text:'', round:0, time:0})
            }} className={'btn'}>Добавить событие</div>
            {isVisibleEvent?
                <div>
                    <table>
                        <tbody>
                        <tr>
                            <td>Цена</td>
                            <td>Текст</td>
                            <td>Раунд</td>
                            <td>Продолжительность(в секундах)</td>
                        </tr>
                        <tr>
                            <td>
                                <input value={event.price} type="text"
                                       onChange={(e)=>{changeEvent('price', Number(e.target.value))}}/>
                            </td>
                            <td>
                                 <textarea value={event.text}  onChange={(e)=>{changeEvent('text', e.target.value)}}
                                                  placeholder={'Текст события'}/>
                            </td>
                            <td>
                                <input value={event.round}  onChange={(e)=>{changeEvent('round', Number(e.target.value))}}
                                       type="text" placeholder={'раунд'}/>
                            </td>
                            <td>
                                <input value={event.time}  onChange={(e)=>{changeEvent('time', Number(e.target.value))}}
                                       type="text" placeholder={'продолжительность'}/>
                            </td>
                        </tr>
                        </tbody>
                    </table>


                    <div onClick={()=>{addEvent()}} className={'btn'}>Создать</div>
                </div>:<></>}
            {eventList.length>0?
                <table>
                    <tbody>
                    <tr>
                        <td>#</td>
                        <td>Цена</td>
                        <td>Текст</td>
                        <td>Раунд</td>
                        <td>Продолжительность(в секундах)</td>
                    </tr>
                    {eventList.map((e,i)=>{
                   return(

                           <tr onClick={()=>{
                               if (window.confirm( `Удалить событие ${e.round} раунда?` ))
                                   delEvent(i)
                           }} key={'tr_event_list'+i}>
                               <td>{i+1})</td>
                               <td>{e.price}</td>
                               <td>{e.text}</td>
                               <td>{e.round}</td>
                               <td>{e.time}</td>
                           </tr>

                   )
               })}
                    </tbody>
                </table>
                :<></>
            }
            {keyWords.length}
            {keyWords.length>0?<div>
                <div className={s.title_key_words}>Введенные слова</div>
                <table className={s.table_key_words}>
                    <tbody>
                    <tr>
                        <th>#</th>
                        <th>команда</th>
                        <th>слово</th>
                        <th>тип</th>
                        <th>цена</th>
                        <th>время</th>
                    </tr>
                    {keyWords.map((key, index)=>{
                        const currentTime = new Date(key.date)
                        return(
                            <tr key={'row_key_word_'+index}>
                                <td>{index+1}</td>
                                <td>{key.team}</td>
                                <td>{key.word}</td>
                                <td>{key.type}</td>
                                <td>{key.price}</td>
                                <td>{currentTime.getHours()}:{currentTime.getMinutes()}:{currentTime.getSeconds()}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>:<></>}
            <ErrorTableComponent errorList={errorMessage}/>
        </div>
    ):
        (
            <Loader/>
        )
};

export default ControlGame;