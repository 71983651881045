import getResponse from '../../Service/ResponceService'
import config from "../../config";
import MapClass from "./MapClass";

class MapState{

    async getMapsByGameId(game_id){
        const result = await getResponse(config.URL_GET_BY_GAME_ID_MAP,{game_id})
        console.log('result',result)
        if (!result.warning)
            return result.maps
        else
            return []
    }

    async addMap(map){
        const result = await getResponse(config.URL_ADD_MAP, {map})
        if (result.warning)
            return null
        else
            return result.map
    }

    async deleteMap(map_id){
        const result = await getResponse(config.URL_DELETE_MAP, {map_id})
        return !result.warning;
    }

    async updateMap(map){
        const result = await getResponse(config.URL_UPDATE_MAP, {map})
        if (result.warning){
            return null
        }else{
            return result.map
        }
    }

    getEmpty(){
        return new MapClass()
    }


}

export default new MapState()

