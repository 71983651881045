export default {
    URL_API: 'https://quizserver.vityazgroup.ru:8501/api/',
    SERVER_SOCKET:'wss://quizserver.vityazgroup.ru:8501',

    URL_GET_USERS:'user/get',
    URL_GET_USERS_BY_GAME_ID:'user/get_by_game_id',
    URL_LOGIN:'user/login',
    URL_ADD_USER:'user/registration',
    URL_UPDATE_USER:'user/update',
    URL_UPDATE_USER_PASSWORD:'user/update_password',
    URL_DELETE_USER:'user/del',


    URL_GET_GAMES: 'game/get',
    URL_ADD_GAME: 'game/add',
    URL_UPDATE_GAME: 'game/update',
    URL_DELETE_GAME: 'game/del',

    URL_GET_TASK_BY_GAME_ID: 'task/get_by_game_id',
    URL_ADD_TASK: 'task/add',
    URL_UPDATE_TASK: 'task/update',
    URL_DELETE_TASK: 'task/del',

    URL_GET_DURATION_TASK_BY_GAME_ID: 'duration_task/get_by_game_id',
    URL_ADD_DURATION_TASK: 'duration_task/add',
    URL_UPDATE_DURATION_TASK: 'duration_task/update',
    URL_DELETE_DURATION_TASK: 'duration_task/del',

    URL_GET_KEYS_BY_GAME_ID: 'keys/get_by_game_id',
    URL_GET_KEYS: 'keys/get',
    URL_ADD_KEYS: 'keys/add',
    URL_UPDATE_KEYS: 'keys/update',
    URL_DELETE_KEYS: 'keys/del',

    URL_GET_MAP: 'map/get',
    URL_ADD_MAP: 'map/add',
    URL_UPDATE_MAP: 'map/update',
    URL_DELETE_MAP: 'map/del',
    URL_GET_BY_GAME_ID_MAP: 'map/get_by_game_id',

    TYPES_GAME:[
        'king',
        'stalker'
    ],

    ROLES_USER:[
        'player',
        'manager',
        'admin'
    ]
}