import './App.css';
import React, {useEffect, useState} from "react";
import ChoiceComponent from "./Pages/ChoiceComponent/ChoiceComponent";
import NavBar from "./Pages/Static/NavBar/NavBar";
import GameAdministrator from "./Pages/GameAdministrator/GameAdministrator";
import ConstructorPage from "./Pages/ConstructorPage/ConstructorPage";
import Cookie from 'js-cookie'
import AuthPage from "./Pages/AuthPage/AuthPage";
import ManagerPage from "./Pages/GameAdministrator/ManagerPage/ManagerPage";

function App() {
  const [point, setPoint] = useState('home')
  useEffect(()=>{

  },[])
  let Component
  const token = Cookie.get('token')
  const role = Cookie.get('role')
  const game = Cookie.get('game')
  if (token && role){
    switch (point) {
      case "home":
        Component = <ChoiceComponent role={role} point={point} setPoint={setPoint}/>
        break
      case 'game':
        Component = <GameAdministrator role={role} token={token}/>
        break
      case 'constructor':
        Component =<ConstructorPage/>
        break
    }
  }else
    return(
        <>
          <NavBar/>
          <AuthPage/>
        </>
    )

  if (role === 'admin')
  return (
    <div className="App">
        <NavBar role={role} setPoint={setPoint}/>
      {Component}

    </div>
  );
  else if(role === 'manager'){
    return (
        <div className="App">
          <NavBar role={role} setPoint={setPoint}/>
          <ManagerPage token={token} game={game}/>
        </div>
    );
  }
}

export default App;
