import React from 'react';
import s from './TeamsMapComponent.module.css'

const TeamsMapComponent = ({teamsMap,isEvent }) => {
    return (
        <div className={s.wrapper}>
            <div className={s.title_table_map}>Маршрут команд</div>
            {teamsMap.length>0 && !isEvent?
                <table className={s.table_map}>
                    <tbody>
                    {teamsMap.map((teamMap, index)=>{
                        return(
                            <tr key={'tr_team_map_'+index}>
                                <td className={s.team_name}>{teamMap.team.stringName}</td>
                                <td>
                                    <table className={s.table_task}>
                                        <tbody>
                                        <tr>
                                            <th>#</th>
                                            <th>Локация</th>
                                            <th>Название</th>
                                        </tr>
                                            {teamMap.tasks.map((task,i)=>{
                                                if(task)
                                                return(
                                                    <tr key={'tr_team_'+index+i}>
                                                        <td>{i+1}</td>
                                                        <td>{task.location}</td>
                                                        <td>{task.title}</td></tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>:
                <>
                </>
            }
        </div>
    );
};

export default TeamsMapComponent;