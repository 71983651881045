import React, {useEffect, useState} from 'react';
import s from './MapCard.module.css'

const MapCard = ({map, teamName, game, tasks, redactUserMap, addField, delRowTask}) => {

    return (
        <div className={s.wrapper}>
            <div>  {map.id === 'new'? 'Новая карта команды ':'редактирование карты '}"{teamName}"</div>
            {map.tasks.map((task,indexTask)=>{
                return(
                    <div className={s.row_task} key={'card_task_'+indexTask}>
                        <span>{indexTask+1}</span>
                        <span> {task.map((t,i)=>{
                            return(
                                <span  key={'index_map_task'+indexTask+i}>
                                    <select className={s.select_map_task} value={t}
                                            onChange={(e)=>{
                                                redactUserMap(indexTask,i,e.target.value)
                                    }}>
                                        <option value="none">-</option>
                                        {tasks.map((task,index)=>{
                                            return(
                                                <option key={'tasks_choice'+indexTask+i+index} value={task.id}>{task.title}/{task.location}</option>
                                            )
                                        })}
                                    </select>
                                </span>
                            )
                        })} </span>
                        <button onClick={()=>{addField(indexTask, 1)}}>+</button>
                        <button onClick={()=>{addField(indexTask,-1)}}>-</button>
                        <div onClick={()=>{delRowTask(indexTask)}} className={s.btn_del}>Удалить</div>
                    </div>
                )
            })}
        </div>
    );
};

export default MapCard;