import React from 'react';
import s from './TimerComponent.module.css'

const TimerComponent = ({time}) => {
    const minutes = Math.floor(time/60)
    const seconds = time - minutes*60
    return (
        <div className={s.wrapper}>
            {time?
                <div>{minutes>9?minutes:'0'+minutes} : {seconds>9?seconds:'0'+seconds}</div>:<></>
            }
        </div>
    );
};

export default TimerComponent;