export default class durationTaskClass{
    id
    game
    type
    title
    text
    mark
    location
    // prices
    changes
    isActive
    description

    constructor() {
        this.id = 'new'
        this.game = ''
        this.type = ''
        this.title = ''
        this.text = ''
        this.mark = ''
        this.location= ''
        // this.prices = [{
        //
        // }]
        this.changes=[{
            text: '',
            prices: {}
        }]
        this.isActive = true
        this.description = ''
    }
}