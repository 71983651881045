import React from 'react';
import s from './AddOrUpdateGame.module.css'
import config from "../../../../../config";

const AddOrUpdateGame = ({game,changeData, addRowEmptyAdditionalResource, updateAdditionalResource, delRowAdditionalResource}) => {
    console.log(game)
    return (
        <div>
            {game.id==='new'?<div>Создание новой игры</div>:<div>Изменить игру</div>}
            <div>Название игры</div>
            <input type="text"
                   className={s.input_box}
                   placeholder={'Название игры'}
                   value={game.gameName}
                   onChange={(e)=>{
                       changeData('gameName', e.target.value)
                   }}/>

            <div>Описание игры</div>
            <input type="text"
                   className={s.input_box}
                   placeholder={'Описание игры'}
                   value={game.description}
                   onChange={(e)=>{
                       changeData('description', e.target.value)
                   }}/>

            <div>Тип игры</div>
            <select value={game.typeGame}
                    onChange={(e)=>{
                        changeData('typeGame', e.target.value)
                    }}>
                <option value="">-</option>
                {config.TYPES_GAME.map(type=><option key={'option_type_game'+type} value={type}>{type}</option> )}
            </select>
            <div className={s.title_field}>Дополнительные ресурсы</div>
            {game.additionalResource.length>0?
                <table className={s.table_additional_resource}>
                    <tbody>
                    <tr>
                        <td>код</td>
                        <td>название</td>
                        <td>начальное кол-во</td>
                        <td></td>
                    </tr>
                    {
                        game.additionalResource.map((resource, index) => {
                            return (
                                <tr key={'tr_resource'+index}>
                                    <td><input type="text" value={resource.name} onChange={(e)=>{updateAdditionalResource(index, 'name', e.target.value)}}/></td>
                                    <td><input type="text" value={resource.stringName} onChange={(e)=>{updateAdditionalResource(index, 'stringName', e.target.value)}}/></td>
                                    <td><input type="text" value={resource.startCount} onChange={(e)=>{updateAdditionalResource(index, 'startCount', Number(e.target.value))}}/></td>
                                    <td><button onClick={()=>delRowAdditionalResource(index)}>-</button></td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>:<></>
            }
            <div onClick={()=>{addRowEmptyAdditionalResource()}} className={'btn'}>Добавить</div>
            <div>Количество раундов</div>
            <input type="text"
                   className={s.input_box}
                   placeholder={'Кол-во раундов'}
                   value={game.countRound}
                   onChange={(e)=>{
                    changeData('countRound', Number(e.target.value))
            }}/>

            <div>Время показа счета в конце раунда</div>
            <input type="text"
                   className={s.input_box}
                   placeholder={'Время показа счета в конце раунда'}
                   value={game.timeViewScore}
                   onChange={(e)=>{
                    changeData('timeViewScore', Number(e.target.value))
            }}/>

            <div>Цена агентского задания</div>
            <input type="text"
                   className={s.input_box}
                   placeholder={'Цена агентского задания'}
                   value={game.agentPrice}
                   onChange={(e)=>{
                    changeData('agentPrice', Number(e.target.value))
            }}/>

            <div>Название игрового ресурса</div>
            <input type="text"
                   className={s.input_box}
                   placeholder={'Название игрового ресурса'}
                   value={game.resourceName}
                   onChange={(e)=>{
                       changeData('resourceName', e.target.value)
                   }}/>

            <div>Начальное количество ресурсов</div>
            <input type="text"
                   className={s.input_box}
                   placeholder={'Начальное кол-во ресурсов'}
                   value={game.startResourceCounts}
                   onChange={(e)=>{
                       changeData('startResourceCounts', Number(e.target.value))
                   }}/>

            <div>Время раунда</div>
            <input type="text"
                   className={s.input_box}
                   placeholder={'Время раунда'}
                   value={game.timeRound}
                   onChange={(e)=>{
                       changeData('timeRound', Number(e.target.value))
                   }}/>

            <input type={'checkbox'}
                   className={s.check_box}
                   checked={game.isActive}
                   onChange={(e)=>{
                       changeData('isActive', e.target.checked)
                   }}/> <span>Игра активна?</span>

        </div>
    );
};

export default AddOrUpdateGame;