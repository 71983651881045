import React from 'react';
import s from './MenuComponent.module.css'

const MenuComponent = ({setPoint, points, activePoint}) => {
    return (
        <div>
            {points.map((point, index)=>{
                return(
                    <div className={activePoint === point.point?s.activePoint:s.point} key={'point_constructor_menu_'+index}
                         onClick={()=>setPoint(point.point)}>{point.pointName}</div>
                )
            })}
        </div>
    );
};

export default MenuComponent;