import React from 'react';
import s from './AddOrUpdateTask.module.css'

const AddOrUpdateTask = ({task,changeData, games }) => {
    console.log(task)
    return (
        <div>
            {task.id==='new'?<div>Создание нового задания</div>:<div>Изменить задание</div>}
            <div>Название задания</div>
            <input type="text"
                   className={s.input_box}
                   placeholder={'Название'}
                   value={task.title}
                   onChange={(e)=>{
                       changeData('title', e.target.value)
                   }}/>

            <div>тип задания</div>
            <input type="text"
                   className={s.input_box}
                   placeholder={'Тип'}
                   value={task.type}
                   onChange={(e)=>{
                       changeData('type', e.target.value)
                   }}/>

            <div>Локация</div>
            <input type="text"
                   className={s.input_box}
                   placeholder={'Локация'}
                   value={task.location}
                   onChange={(e)=>{
                       changeData('location', e.target.value)
                   }}/>

            <div>Цена задания</div>
            <input type="text"
                   className={s.input_box}
                   placeholder={'Цена'}
                   value={task.price}
                   onChange={(e)=>{
                       changeData('price', Number(e.target.value))
                   }}/>

                   <div>Цена задания при ничьей</div>
            <input type="text"
                   className={s.input_box}
                   placeholder={'Цена (ничья)'}
                   value={task.priceDraw}
                   onChange={(e)=>{
                       changeData('priceDraw', Number(e.target.value))
                   }}/>

            <div>Модификатор цены раунда</div>
            <input type="text"
                   className={s.input_box}
                   placeholder={'Модификатор цены за раунд'}
                   value={task.priceModifier}
                   onChange={(e)=>{
                       changeData('priceModifier', Number(e.target.value))
                   }}/>

                   <div>Длительность задания (в раундах)</div>
            <input type="text"
                   className={s.input_box}
                   placeholder={'Длительность'}
                   value={task.countDuration}
                   onChange={(e)=>{
                       changeData('countDuration', Number(e.target.value))
                   }}/>

            <div>Текст задания</div>
            <input type="text"
                   className={s.input_box}
                   placeholder={'Текст задания'}
                   value={task.text}
                   onChange={(e)=>{
                   changeData('text',e.target.value)
            }}/>

            <div>Игра</div>
            <select defaultValue={task.game} onChange={(e)=>{
                changeData('game',e.target.value)
            }}>{games.map((game,index)=>(
                <option key={'game_list_redact_user_'+index} value={game.id}>{game.gameName}</option>
            ))}

            </select>

            {/*<input type="text"*/}
            {/*       className={s.input_box}*/}
            {/*       placeholder={'Игра'}*/}
            {/*       value={user.game}*/}
            {/*       onChange={(e)=>{*/}
            {/*           changeData('game', e.target.value)*/}
            {/*       }}/>*/}

            <div>Описание</div>
            <input type="text"
                   className={s.input_box}
                   placeholder={'Описание'}
                   value={task.description}
                   onChange={(e)=>{
                       changeData('description', e.target.value)
                   }}/>

            <div>Маркер задания (для сопоставления)</div>
            <input type="text"
                   className={s.input_box}
                   placeholder={'маркер задания'}
                   value={task.mark}
                   onChange={(e)=>{
                       changeData('mark', e.target.value)
                   }}/>

            <input type={'checkbox'}
                   className={s.check_box}
                   checked={task.isActive}
                   onChange={(e)=>{
                       changeData('isActive', e.target.checked)
                   }}/> <span>Игра активна?</span>

        </div>
    );
};

export default AddOrUpdateTask;