export  default class GameClass{

    id
    gameName
    countRound
    typeGame
    isActive
    resourceName
    startResourceCounts
    additionalResource
    timeRound
    description

    constructor() {
        this.id = 'new'
        this.gameName = ''
        this.typeGame = ''
        this.countRound =0
        this.timeRound = 0
        this.timeViewScore = 0
        this.isActive = true
        this.resourceName = ''
        this.startResourceCounts = 0
        this.additionalResource = {}
        this.agentPrice = 0
        this.description = ''
    }
}