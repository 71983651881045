import getResponse from '../../Service/ResponceService'
import config from "../../config";
import DurationTaskClass from "./DurationTaskClass";

class DurationTaskState{

    async getDurationTasksByGameId(game_id){
        const result = await getResponse(config.URL_GET_DURATION_TASK_BY_GAME_ID,{game_id})
        if (!result.warning){
            return result.tasks
        }
        else
            return []

    }

    async addDurationTask(task){
        const result = await getResponse(config.URL_ADD_DURATION_TASK, {task})
        if (result.warning){
            return null
        }else{
            return result.task
        }
    }

    async deleteDurationTask(task_id){
        const result = await getResponse(config.URL_DELETE_DURATION_TASK, {task_id})
        return !result.warning;
    }

    async updateDurationTask(task){
        const result = await getResponse(config.URL_UPDATE_DURATION_TASK, {task})
        if (result.warning){
            return null
        }else{
            return result.task
        }
    }

    getEmpty(){
        return new DurationTaskClass()
    }


}

export default new DurationTaskState()

