
export default class GameControlState{
    ws
    constructor(ws) {
    this.ws = ws
    }

    sendStart(){
       this.sendMessage({action:'start'})
    }

    sendStop(){
        this.sendMessage({action:'stop'})
    }

    sendRefresh(){
        this.sendMessage({action:'refresh'})
    }

    sendMessage(message){
        this.ws.send(JSON.stringify(message))
    }
}
