import React, {useEffect, useState} from 'react';
import s from './TaskConstructor.module.css'
import GameState from "../../../../State/GameState/GameState";
import Cookie from "js-cookie";
import TaskState from "../../../../State/TaskState/TaskState";
import Loader from "../../../Static/Loader/Loader";
import AddOrUpdateTask from "./AddOrUpdateTask/AddOrUpdateTask";
import TaskCard from "./TaskCard/TaskCard";

const TaskConstructor = () => {
    const [games, setGames] = useState([])
    const [tasks, setTasks] = useState([])
    const [activeGame, setActiveGame] = useState('')
    const [redactTask, setRedactTask] = useState(null)
    const [isLoad, setIsLoad] = useState(false)
    const [isRedactTask, setIsRedactTask] = useState(false)

    const loadTaskByGameId = async(gameId)=>{
        if (gameId === 'none')
            return false
        const resTasks = await TaskState.getTasksByGameId(gameId)
        if (resTasks){
            setTasks(resTasks)
            return true
        }else
            return true

    }

    const init = async(cookieActiveGame)=>{
        const resGame = await GameState.getGames()
        if(resGame){
            setGames(resGame)
        }
        let resTasks
        if ((!cookieActiveGame || !games.find(g=>g.id===cookieActiveGame) && resGame.length>0)){
           await loadTaskByGameId(resGame[0].id)

        } else if(resGame.length>0){
           await loadTaskByGameId(activeGame)
        }else{
            setActiveGame('none')
        }
       if (resTasks){
           setTasks(resTasks)
       }
        setIsLoad(true)
    }



    const changeData = (fieldName, value)=>{
        const redact = {...redactTask}
        redact[fieldName] = value
        setRedactTask(redact)
    }

    const updatedTask = (task)=>{
        setRedactTask(task)
        setIsRedactTask(true)
    }


    const deleteTask = async(userId)=>{
        return await TaskState.deleteTask(userId)

    }

    const saveTask = async ()=>{
        console.log(redactTask.id)
        if (redactTask.id === 'new') {
            const res = await TaskState.addTask(redactTask)
            console.log(res)
            if (res) {
                setTasks([...tasks, res])
                return true
            }else{
                return false
            }
        }else{
            const res = await TaskState.updateTask(redactTask)

            if (res) {
                setTasks([...tasks.filter(t => t.id !== redactTask.id), res])
                return true
            }
            else
                return false
        }

    }

    useEffect(()=>{
        const cookieActiveGame = Cookie.get('active_game')
        if (cookieActiveGame){
            setActiveGame(cookieActiveGame)
        }
        init(cookieActiveGame)
    },[])
    return isLoad?(
        <div>
            {games.length>0?
            <div>
                {isRedactTask?
                    <div>
                    <AddOrUpdateTask task={redactTask} games={games} changeData={changeData} />
                    <div onClick={()=>setIsRedactTask(false)} className={'btn'}>Закрыть</div>
                    <div onClick={async (e)=>{
                        e.target.innerText = 'Сохраняю...'
                        if(await saveTask())
                            e.target.innerText = 'Сохранено'
                        else
                            e.target.innerText = 'Ошибка'
                        setTimeout(()=>{
                            e.target.innerText = 'Сохранить'
                        },1200)
                    }} className={'btn'}>Сохранить</div>
                    </div>:
                    <div>
                        <select onChange={async (e)=>{
                            Cookie.set('active_game', e.target.value)
                            setActiveGame(e.target.value)
                            await loadTaskByGameId(e.target.value)
                            // if (e.target.value !== 'none') {
                            //
                            // }
                        }} defaultValue={activeGame}>
                            <option value="none">Выберите игру</option>
                            {games.map((game, index)=>{
                                return(
                                    <option key={'user_page_game_option_'+index} value={game.id}>{game.gameName}</option>
                                )
                            })}
                        </select>

                        {tasks.length>0?
                            <div>
                                {tasks.map((task, index)=>{
                                    console.log()
                                    return (
                                        <TaskCard key={'task_card_constructor_'+index}
                                                  task={task}  delTask={deleteTask}
                                                  gameName={games.find(g=>g.id === task.game)?games.find(g=>g.id === task.game).gameName:'none'}
                                                  updatedTask={updatedTask} />
                                    )
                                })}
                            </div>:
                            <div>Нет заданий</div>
                        }

                        <div
                            onClick={()=>{
                                const emptyTask = TaskState.getEmpty()
                                console.log(activeGame)
                                emptyTask.game = activeGame
                                setRedactTask(emptyTask)
                                setIsRedactTask(true)
                            }}
                            className={'btn'}>Добавить</div>
                    </div>
                }
                    </div>:
            <div>
                Нет активных игр
            </div>}

        </div>
    ):(
        <Loader/>
    )
};

export default TaskConstructor;