import React, {useEffect, useState} from 'react';
import s from './CollectionKeysConstructor.module.css'
import Loader from "../../../Static/Loader/Loader";
import GameState from "../../../../State/GameState/GameState";
import KeysState from "../../../../State/KeysState/KeysState";
import KeyBox from "./KeyBox/KeyBox";

const CollectionKeysConstructor = () => {
    const [isLoad, setIsLoad] = useState(false)
    const [games, setGames] = useState([])
    const [keys, setKeys] = useState(null)
    const [currentGameId, setCurrentGameId] = useState('none')

    const init = async () => {
        const resGame = await GameState.getGames()
        setGames(resGame)
        setIsLoad(true)
    }

    const addRowKey = (keyName) => {
        const newKeys = {...keys}
        newKeys[keyName].push({key:'', mark:''})
        setKeys(newKeys)
    }

    const redactKey = (keyName, fieldName, index, value) => {
        const newKeys = {...keys}
        console.log(keyName, fieldName, index, value)
        newKeys[keyName][index][fieldName] = value
        setKeys(newKeys)
    }

    const delKey = (keyName, index) => {
        const newKeys = {...keys}
        newKeys[keyName] = newKeys[keyName].filter((k, i) => index !== i)
        setKeys(newKeys)
    }

    const saveCollectionKey = async () => {
        if (keys.id === 'new') {
            const res = await KeysState.addKeys(keys)
            if (res) {
                setKeys(res)
                return true
            } else return false


        } else {
            const res = await KeysState.updateKeys(keys)
            if (res) {
                setKeys(res)
                return true
            } else return false
        }

    }

    const changeGame = async (gameId) => {
        if (gameId === 'none') {
            setKeys(null)
            setCurrentGameId('none')
            return
        }
        setIsLoad(false)
        setCurrentGameId(gameId)
        const newKeyList = await KeysState.getKeysByGameId(gameId)
        console.log(newKeyList)
        if (newKeyList)
            setKeys(newKeyList)
        else
            setKeys(KeysState.getEmpty(gameId))
        setIsLoad(true)
    }

    useEffect(() => {
        init()
    }, [])
    if (!isLoad)
        return (
            <Loader/>
        )

    return (
        <div>
            {games.length > 0 ?
                <div>
                    <select value={currentGameId} onChange={async (e) => changeGame(e.target.value)}>
                        <option value={'none'}>Выберите игру</option>
                        {games.map((game, i) => {
                            return (
                                <option key={"key_col_game_option" + i} value={game.id}>{game.gameName}</option>
                            )
                        })}
                    </select>
                    {keys ?
                        <>
                            <KeyBox collectionKeys={keys}
                                    redactKey={redactKey}
                                    delKey={delKey}
                                    addRowKey={addRowKey} />
                            <div onClick={async (e)=> {
                                e.target.innerText = 'Сохраняю...'
                               if( await saveCollectionKey())
                                   e.target.innerText = 'Сохранено'
                                else
                                   e.target.innerText = 'Ошибка'
                                setTimeout(()=>{
                                    e.target.innerText = 'Сохранить'
                                },1200)

                            }} className="btn">Сохранить</div>
                        </>
                        : <></>}
                </div> :
                <div>Нет доступных игр</div>}
        </div>
    );
};

export default CollectionKeysConstructor;