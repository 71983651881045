export default class taskClass{
    id
    game
    type
    title
    text
    mark
    key
    wrongKey
    location
    price
    priceDraw
    priceModifier
    timeDuration
    countDuration
    choice
    isActive
    description

    constructor() {
        this.id = 'new'
        this.game = ''
        this.type = ''
        this.title = ''
        this.text = ''
        this.key = ''
        this.wrongKey = ''
        this.mark = ''
        this.location= ''
        this.price = 0
        this.priceDraw = 0
        this.timeDuration = 0
        this.priceModifier = 0
        this.countDuration=1
        this.choice = ''
        this.isActive = true
        this.description = ''
    }
}