import React from 'react';
import s from './errorTableComponent.module.css'

const ErrorTableComponent = ({errorList}) => {
    return (
        <>
            <div>Ошибки</div>
            <table>
                <tbody>
                {errorList.map((err,index)=>{
                    return(
                        <tr key={'row_error'+index}>
                            <td>{err}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </>
    );
};

export default ErrorTableComponent;