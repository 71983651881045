import React from 'react';

// collectionKeys={keys}
// redactKey={redactKey}
// delKey={delKey}
// addRowKey={addRowKey}

const KeyBox = ({collectionKeys, redactKey, delKey, addRowKey}) => {
    return (
        <div>
            <div>Ключи правильных ответ</div>
            <table>
                <tbody>
                    <tr>
                        <th>#</th>
                        <th>Ключ</th>
                        <th>Маркер</th>
                        <th>Цена</th>
                        <th>Удалить</th>
                    </tr>

            {collectionKeys.correctKey.map((key, i)=>{
                return(
                    <tr key={'correct_key'+i}>
                        <td>{i+1})</td>
                        <td>
                            <input
                                placeholder={'ключ'}
                                onChange={e=>redactKey('correctKey', 'key',i, e.target.value)}
                                type="text" value={key.key}/>
                        </td>
                        <td>
                            <input
                                onChange={e=>redactKey('correctKey','mark',i, e.target.value)}
                                placeholder={'маркер'}
                                type="text" value={key.mark}/>
                        </td>
                        <td>
                            <input
                                onChange={e=>redactKey('correctKey','price',i, Number(e.target.value))}
                                placeholder={'цена'}
                                type="text" value={key.price}/>
                        </td>
                        <td>
                            <button onClick={()=>delKey('correctKey', i)}>-</button>
                        </td>
                    </tr>

                )
            })}
                </tbody>
            </table>
            <div onClick={()=>{
                addRowKey('correctKey')
            }} className={'btn'}>Добавить</div>

            <div>Ключи неправильных ответов</div>
            <table>
                <tbody>
                <tr>
                    <th>#</th>
                    <th>Ключ</th>
                    <th>Маркер</th>
                    <th>Цена</th>
                    <th>Удалить</th>
                </tr>
            {collectionKeys.incorrectKey.map((key, i)=>{
                return(
                    <tr key={'incorrect_key'+i}>
                        <td>{i+1})</td>
                        <td>
                            <input
                                onChange={e=>redactKey('incorrectKey', 'key',i, e.target.value)}
                                placeholder={'ключ'}
                                type="text" value={key.key}/>
                        </td>
                        <td>
                            <input
                                onChange={e=>redactKey('incorrectKey', 'mark',i, e.target.value)}
                                placeholder={'маркер'}
                                type="text" value={key.mark}/>
                        </td>
                        <td>
                            <input
                                onChange={e=>redactKey('incorrectKey','price',i, Number(e.target.value))}
                                placeholder={'цена'}
                                type="text" value={key.price}/>
                        </td>
                        <td>
                            <button onClick={()=>delKey('incorrectKey', i)}>-</button>
                        </td>
                    </tr>

                )
            })}
                </tbody>
            </table>

            <div onClick={()=>{
                addRowKey('incorrectKey')
            }} className={'btn'}>Добавить</div>

            <div>Ключи при игре в ничью</div>
            <table>
                <tbody>
                <tr>
                    <th>#</th>
                    <th>Ключ</th>
                    <th>Маркер</th>
                    <th>Цена</th>
                    <th>Удалить</th>
                </tr>
            {collectionKeys.drawKey.map((key, i)=>{
                return(
                  <tr  key={'drawKey_key'+i}>
                      <td>{i+1})</td>
                      <td>
                          <input
                              onChange={e=>redactKey('drawKey', 'key',i, e.target.value)}
                              placeholder={'ключ'}
                              type="text" value={key.key}/>
                      </td>
                      <td>
                          <input
                              onChange={e=>redactKey('drawKey', 'mark',i, e.target.value)}
                              placeholder={'маркер'}
                              type="text" value={key.mark}/>
                      </td>
                      <td>
                          <input
                              onChange={e=>redactKey('drawKey','price',i, Number(e.target.value))}
                              placeholder={'цена'}
                              type="text" value={key.price}/>
                      </td>
                      <td>
                          <button onClick={()=>delKey('drawKey', i)}>-</button>
                      </td>



                  </tr>
                )
            })}
                </tbody>
            </table>
            <div onClick={()=>{
                addRowKey('drawKey')
            }} className={'btn'}>Добавить</div>
        </div>
    );
};

export default KeyBox;