import getResponse from '../../Service/ResponceService'
import config from "../../config";
import GameClass from "./GameClass";

class GameState{
    games = []
    isLoad = false

    async getGames(){
        const result = await getResponse(config.URL_GET_GAMES)
        if (!result.warning){
            this.games = result.games
            this.isLoad = true
            return result.games
        }
        else
            return []
    }

    async addGame(game){
        const result = await getResponse(config.URL_ADD_GAME, {game})
        if (result.warning){
            return null
        }else{
            this.games.push(result.game)
            return result.game
        }
    }

    async deleteGame(game_id){
        const result = await getResponse(config.URL_DELETE_GAME, {game_id})
        if (!result.warning)
            this.games = this.games.filer(game=>game.id!==game_id)
        return !result.warning;
    }

    async updateGame(game){
        const result = await getResponse(config.URL_UPDATE_GAME, {game})
        if (result.warning){
            return null
        }else{
            if (!result.warning)
                this.games = [...  this.games.filter(g=> g.id !== game.id), result.game]
            return result.game
        }
    }

    getEmptyGame(){
        return new GameClass()
    }
}

export default new GameState()

