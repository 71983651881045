import React from 'react';
import s from './AddOrUpdateUser.module.css'
import config from "../../../../../config";

const AddOrUpdateUser = ({user,changeData, games }) => {
    console.log(user)
    return (
        <div>
            {user.id==='new'?<div>Создание нового пользователя</div>:<div>Изменить пользователя</div>}
            <div>Имя учетной записи</div>
            <input type="text"
                   className={s.input_box}
                   placeholder={'Имя учетной записи'}
                   value={user.username}
                   onChange={(e)=>{
                       changeData('username', e.target.value)
                   }}/>
            {user.id==='new'?
            <div>
                <div>Пароль</div>
                <input type="text"
                       className={s.input_box}
                       placeholder={'Пароль'}
                       onChange={(e)=>{
                           changeData('password', e.target.value)
                       }}
                       value={user.password} />
            </div>:<></>}
            <div>Название команды</div>
            <input type="text"
                   className={s.input_box}
                   placeholder={'Название команды'}
                   value={user.stringName}
                   onChange={(e)=>{
                       changeData('stringName', e.target.value)
                   }}/>

            <div>Роль</div>
            <select value={user.role}
                    onChange={(e)=>{
                        changeData('role',e.target.value)
                    }}>
                <option value="">-</option>
                {config.ROLES_USER.map(role=><option key={'option_role_'+role} value={role}>{role}</option> )}
            </select>

            <div>Игра</div>
            <select defaultValue={user.game} onChange={(e)=>{
                changeData('game',e.target.value)
            }}>{games.map((game,index)=>(
                <option key={'game_list_redact_user_'+index} value={game.id}>{game.gameName}</option>
            ))}

            </select>

            {/*<input type="text"*/}
            {/*       className={s.input_box}*/}
            {/*       placeholder={'Игра'}*/}
            {/*       value={user.game}*/}
            {/*       onChange={(e)=>{*/}
            {/*           changeData('game', e.target.value)*/}
            {/*       }}/>*/}

            <div>Описание</div>
            <input type="text"
                   className={s.input_box}
                   placeholder={'Описание'}
                   value={user.description}
                   onChange={(e)=>{
                       changeData('description', e.target.value)
                   }}/>

            <input type={'checkbox'}
                   className={s.check_box}
                   checked={user.isActive}
                   onChange={(e)=>{
                       changeData('isActive', e.target.checked)
                   }}/> <span>Игра активна?</span>

        </div>
    );
};

export default AddOrUpdateUser;