import React, {useEffect, useState} from 'react';
import Loader from "../../../Static/Loader/Loader";
import UserState from "../../../../State/UserState/UserState";
import GameState from "../../../../State/GameState/GameState";
import Cookie from 'js-cookie'
import AddOrUpdateUser from "./AddOrUpdateUser/AddOrUpdateUser";
import UserCard from "./UserCard/UserCard";

const UserConstructor = () => {
    const [users, setUsers] = useState([])
    const [games, setGames] = useState([])
    const [isLoad, setIsLoad] = useState(false)
    const [filterUserList, setFilterUserList] = useState([])
    const [filterGame, setFilterGame] = useState('none')
    const [isRedactGame, setIsRedactGame] = useState(false)
    const [redactUser, setRedactUser] = useState(null)

    const init = async (cookieActiveGame) =>{
        const resUsers = await UserState.getUsers()
        const resGames = await GameState.getGames()
        if (resUsers && resGames){
            setGames(resGames)
            setUsers(resUsers)
            if(!cookieActiveGame)
                setFilterUserList(resUsers)
            else
                setFilterUserList(resUsers.filter(u=>u.game === cookieActiveGame))
        }
        else
            setTimeout(()=>{init()},1200)
        setIsLoad(true)
    }
    const changeData = (fieldName, value)=>{
        const redact = {...redactUser}
        redact[fieldName] = value
        setRedactUser(redact)
    }
    const updatedUser = (user)=>{
        setRedactUser(user)
        setIsRedactGame(true)
    }

    const updatePassword = async (userId, newPassword)=>{
        return await UserState.updatePassword(userId, newPassword)
    }

    const deleteUser = async(userId)=>{
        return await UserState.deleteUser(userId)

    }

    const saveUser = async ()=>{

        if (redactUser.id === 'new') {
            const res = await UserState.addUser(redactUser)
            if (res) {
                setUsers([...users, res])
                setFilterUserList ([...filterUserList, res])
                return true
            }else{
                return false
            }
        }else{
            const res = await UserState.updateUser(redactUser)

            if (res) {
                setUsers([...users.filter(game => game.id !== redactUser.id), res])
                setFilterUserList([...users.filter(u=>u.id!== redactUser.id), res])
                return true
            }
            else
                return false
        }

    }
    useEffect(()=>{
        const cookieActiveGame = Cookie.get('active_game')
        if (cookieActiveGame){
            console.log(cookieActiveGame)
            setFilterGame(cookieActiveGame)
        }
        console.log('1111')
        init(cookieActiveGame)
    },[])

    return isLoad?(
        <>
            {isRedactGame?
                <div>

                    <AddOrUpdateUser activeGame={filterGame} games={games} user={redactUser} changeData={changeData}/>
                    <div onClick={()=>{
                        setIsRedactGame(false)
                    }} className={'btn '}>Закрыть</div>

                    <div onClick={async (e)=>{
                        e.target.innerText = 'Сохраняю...'
                        const res = await saveUser()
                        if (res){
                            e.target.innerText = 'Сохранено'
                        }else
                            e.target.innerText = 'Ошибка'
                        setTimeout(()=>{
                            e.target.innerText = 'Сохранить'
                        },1200)

                    }} className={'btn'}>Сохранить</div>
                </div>:
                <div>
                    <select onChange={(e)=>{
                        Cookie.set('active_game', e.target.value)
                        setFilterGame(e.target.value)
                        if (e.target.value === 'none'){
                            setFilterUserList(users)
                        }else{
                            setFilterUserList(users.filter(u=>u.game === e.target.value))
                        }
                    }} defaultValue={filterGame}>
                        <option value="none">Выберите игру</option>
                        {games.map((game, index)=>{
                            return(
                                <option key={'user_page_game_option_'+index} value={game.id}>{game.gameName}</option>
                            )
                        })}
                    </select>

                    {filterUserList.map((user, index)=>{
                        return(
                            <UserCard key={'user_card_constructor_'+index}
                                      updatePassword={updatePassword}
                                      user={user}
                                      gameName={games.find(g=>g.id===user.game)?games.find(g=>g.id===user.game).gameName:'none'}
                                      delUser={deleteUser}
                                      updatedUser={updatedUser}/>
                        )
                    })}

                    <div onClick={()=>{
                        const rUser = UserState.getEmptyGame()
                        rUser.game = filterGame
                        setRedactUser(rUser)
                        setIsRedactGame(true)
                    }} className={'btn '}>Добавить</div>
                </div>
            }

            </>
    ):(
        <Loader/>
    )
};

export default UserConstructor;