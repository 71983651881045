import React, {useState} from 'react';
import s from './GameCard.module.css'

const GameCard = ({game, delGame, updatedGame}) => {
    const [isDelGame, setIsDelGame] = useState(false)

    return (
        <div className={s.wrapper}>
            <div>{game.gameName}</div>
            <div>Описание игры: </div>
            <div>{game.description}</div>
            <div>Количесвто раундов: {game.countRound}</div>
            <div>Время раунда раунда: {game.timeRound}</div>
            {isDelGame?<div>Игра удалена</div>:
            <>
                <div onClick={()=>{updatedGame(game)}}
                     className={'btn'} >Изменить</div>

                <div onClick={()=>{
                    if (window.confirm('Удалить игру?'))
                        if(delGame(game.id))
                            setIsDelGame(true)
                }}
                     className={'btn'} >Удалить</div>
            </>
            }

        </div>
    );
};

export default GameCard;