export default class MapClass{
    id
    game
    team
    tasks
    isActive

    constructor() {
        this.id = 'new'
        this.team = ''
        this.game = ''
        this.tasks = []
        this.isActive = true
    }
}
