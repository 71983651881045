import getResponse from '../../Service/ResponceService'
import config from "../../config";
import KeyClass from "./KeysClass";

class KeysState{

    async getKeysByGameId(game_id){
        const result = await getResponse(config.URL_GET_KEYS_BY_GAME_ID,{game_id})
        console.log('result',result)
        if (!result.warning)
            return result.keys
        else
            return null
    }

    async getKeys(){
        const result = await getResponse(config.URL_GET_KEYS)
        console.log('result',result)
        if (!result.warning)
            return result.keys
        else
            return []
    }

    async addKeys(key){
        const result = await getResponse(config.URL_ADD_KEYS, {key})
        if (result.warning)
            return null
        else
            return result.keys
    }

    async deleteKeys(key_id){
        const result = await getResponse(config.URL_DELETE_KEYS, {key_id})
        return !result.warning;
    }

    async updateKeys(key){
        const result = await getResponse(config.URL_UPDATE_KEYS, {key})
        if (result.warning){
            return null
        }else{
            return result.keys
        }
    }

    getEmpty(gameId){
        return new KeyClass(gameId)
    }


}

export default new KeysState()

