import React, {useState} from 'react';
import s from './TaskCard.module.css'

const TaskCard = ({task, delTask, updatedTask, gameName}) => {
    const [isDelTask, setIsDelTask] = useState(false)

    return (
        <div className={s.wrapper}>
            <div>{task.title}</div>
            <div>Локация: {task.location}</div>
            <div>Текст: {task.text}</div>
            <div>Игра: {gameName}</div>
            <div>Тип: {task.type}</div>
            {isDelTask?<div>Задание удалено</div>:
            <>
                <div onClick={()=>{updatedTask(task)}}
                     className={'btn'} >Изменить</div>

                <div onClick={()=>{
                    if (window.confirm('Удалить пользователя?'))
                        if(delTask(task.id))
                            setIsDelTask(true)
                }}
                     className={'btn'} >Удалить</div>
            </>
            }

        </div>
    );
};

export default TaskCard;