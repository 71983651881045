import getResponse from '../../Service/ResponceService'
import config from "../../config";
import TaskClass from "./TaskClass";

class TaskState{

    async getTasksByGameId(game_id){
        const result = await getResponse(config.URL_GET_TASK_BY_GAME_ID,{game_id})
        if (!result.warning){
            return result.tasks
        }
        else
            return []

    }

    async addTask(task){
        const result = await getResponse(config.URL_ADD_TASK, {task})
        if (result.warning){
            return null
        }else{
            return result.task
        }
    }

    async deleteTask(task_id){
        const result = await getResponse(config.URL_DELETE_TASK, {task_id})
        return !result.warning;
    }

    async updateTask(task){
        const result = await getResponse(config.URL_UPDATE_TASK, {task})
        if (result.warning){
            return null
        }else{
            return result.task
        }
    }

    getEmpty(){
        return new TaskClass()
    }


}

export default new TaskState()

