import React, {useState} from 'react';
import s from './UserCard.module.css'

const UserCard = ({user, delUser, updatedUser, gameName, updatePassword}) => {
    const [isDelUser, setIsDelUser] = useState(false)
    const [newPassword, setNewPassword] = useState('')
    const [isNewPassword, setIsNewPassword] = useState(false)

    return (
        <div className={s.wrapper}>
            <div>{user.username}</div>
            <div>Навание команды игры: {user.stringName}</div>
            <div>Роль: {user.role}</div>
            <div>Игра: {gameName}</div>
            <div>Описание: {user.description}</div>
            {isDelUser?<div>Пользователь удален</div>:
            <>
                <div onClick={()=>{updatedUser(user)}}
                     className={'btn'} >Изменить</div>

                <div onClick={()=>{
                    if (window.confirm('Удалить пользователя?'))
                        if(delUser(user.id))
                            setIsDelUser(true)
                }}
                     className={'btn'} >Удалить</div>
                <div onClick={()=>{
                    setIsNewPassword(!isNewPassword)
                }}
                     className={'btn'} >Изменить пароль</div>
                {isNewPassword?<div>
                    <div>Новый пароль</div>
                    <input className={s.input_box} type="text" value={newPassword} onChange={e=>setNewPassword(e.target.value)}/>
                    <div onClick={async(e)=>{
                        e.target.innerText = 'Сохраняю...'
                        const res = await updatePassword(user.id, newPassword.trim())
                        if (res){
                            e.target.innerText = 'Сохранено'
                        }else{
                            e.target.innerText = 'Ошибка'
                        }
                        setTimeout(()=>{
                            e.target.innerText = 'Сохранить'
                        },1200)
                    }} className={'btn'}>Сохранить</div>
                </div>:<></>}
            </>
            }

        </div>
    );
};

export default UserCard;