import React from 'react';
import s from './ScoresComponent.module.css'

const ScoresComponent = ({scores}) => {
    return (
        <>
            {scores?<div className={s.wrapper}>
                <div className={s.title_table_score}>Текущий счет команд</div>
                <table className={s.table_score}>
                    <tbody>
                    <tr>
                        <th>Название команды</th>
                        <th>Приобретена Энергетическая станция</th>
                        <th>счет</th>
                    </tr>
                    {scores.map((score, index)=>{
                        return(
                            <tr key={'row_score_'+index}>
                                <td>{score.team}</td>
                                {score.isBuy?<td  className={s.is_buy_yes}>Да</td>:<td className={s.is_buy_no}>Нет</td>}
                                <td>{score.score}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>:<></>}
        </>
    );
};

export default ScoresComponent;