import React, {useState} from 'react';
import s from './ConstructorPage.module.css'
import MenuComponent from "./MenuComponent/MenuComponent";
import UserConstructor from "./Constructors/UserConstructor/UserConstructor";
import GameConstructor from "./Constructors/GameConstructor/GameConstructor";
import TaskConstructor from "./Constructors/TaskConstructor/TaskConstructor";
import MapConstructor from "./Constructors/MapConstructor/MapConstructor";
import CollectionKeysConstructor from "./Constructors/CollectionKeysConstructor/CollectionKeysConstructor";
import DurationTaskConstructor from "./Constructors/DurationTaskConstructor/DurationTaskConstructor";

const ConstructorPage = () => {
    const [pointMenu, setPointMenu] = useState('none')
    const points = [
        {point:'user', pointName:'Пользователи'},
        {point:'game', pointName:'Игры'},
        {point:'task', pointName:'Задания'},
        {point:'durationTask', pointName:'Дополнительные задания'},
        {point:'map', pointName:'Карты'},
        {point: 'keys', pointName: 'Ключи'}
    ]
    let Component
    switch (pointMenu) {
        case "user":
            Component = <UserConstructor setPoint={setPointMenu} points={points}/>
            break
        case "game":
            Component = <GameConstructor setPoint={setPointMenu} points={points}/>
            break
        case "task":
            Component = <TaskConstructor setPoint={setPointMenu} points={points}/>
            break
        case "map":
            Component = <MapConstructor setPoint={setPointMenu} points={points}/>
            break
        case "keys":
            Component = <CollectionKeysConstructor />
            break
        case "durationTask":
            Component = <DurationTaskConstructor />
            break
        default:
            Component = <></>
    }
    return (
        <div className={s.wrapper}>
            <MenuComponent setPoint={setPointMenu} activePoint={pointMenu} points={points}/>
            {Component}
        </div>
    );
};

export default ConstructorPage;