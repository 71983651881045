import React, {useEffect, useState} from 'react';
import s from './GameConstructor.module.css'
import GameState from "../../../../State/GameState/GameState";
import Loader from "../../../Static/Loader/Loader";
import AddOrUpdateGame from "./AddOrUpdateGame/AddOrUpdateGame";
import GameCard from "./GameCard/GameCard";

const GameConstructor = () => {
    const [games, setGames] = useState([])
    const [isLoad, setIsLoad] = useState(false)
    const [activeGame, setActiveGame] = useState({})
    const [isRedactGame, setIsRedactGame] = useState(false)

    const init = async ()=>{
        setIsLoad(false)
        const resultGames = await GameState.getGames()
        setGames(resultGames)
        setIsLoad(true)
    }

    const changeDataGame = (fieldName, value)=>{
        const redactGame = {...activeGame}
        redactGame[fieldName] = value
        setActiveGame(redactGame)
    }
    const updatedGame = (game)=>{
        setActiveGame(game)
        setIsRedactGame(true)
    }
    const updateAdditionalResource = (index, field, value) =>{
        const currentGame = {...activeGame}
        currentGame.additionalResource[index][field] = value
        setActiveGame(currentGame)
    }
    const addRowEmptyAdditionalResource = () => {
        const currentGame = {...activeGame}
        currentGame.additionalResource.push({name:'', stringName:'', startCount:0})
        setActiveGame(currentGame)
    }

    const delRowAdditionalResource = (index)=>{
        const currentGame = {...activeGame}
        currentGame.additionalResource = currentGame.additionalResource.filter((r,i)=>i!==index)
        setActiveGame(currentGame)
    }

    const deleteGame = async(gameId)=>{
       return await GameState.deleteGame(gameId)

    }

    const saveGame = async ()=>{

        if (activeGame.id === 'new') {
            const res = await GameState.addGame(activeGame)
            if (res) {
                setGames([...games, res])
                return true
            }else{
                return false
            }
        }else{
            const res = await GameState.updateGame(activeGame)
            console.log(res)
            if (res) {
                setGames([...games.filter(game => game.id !== activeGame.id), res])
                return true
            }
            else
                return false
        }

    }

    useEffect(()=>{
       init()
    },[])
    return isLoad?(
        <>
            {!isRedactGame?
                    <div>
                        {games.map((game,index)=>{
                            return(
                                <div key={'game_list_'+index}>
                                    <GameCard game={game} delGame={deleteGame} updatedGame={updatedGame} />
                                </div>
                            )
                        })}
                        <div onClick={()=>{
                            setActiveGame(GameState.getEmptyGame())
                            setIsRedactGame(true)
                        }} className={'btn'}>Добавить</div>
                    </div>:
                    <div>
                        <AddOrUpdateGame addRowEmptyAdditionalResource={addRowEmptyAdditionalResource}
                                         delRowAdditionalResource={delRowAdditionalResource}
                                         updateAdditionalResource={updateAdditionalResource}
                                         changeData={changeDataGame} game={activeGame}/>

                        <div onClick={async(e)=>{
                            e.target.innerText = 'Сохраняю...'
                            if(await saveGame()){
                                e.target.innerText = 'Сохранено'
                            }else {
                                e.target.innerText = 'Ошибка'

                            }
                            setTimeout(()=>{
                                e.target.innerText = 'Сохранить'
                            },1200)
                        }} className={'btn'}>Сохранить</div>
                        <div onClick={()=>{
                            setIsRedactGame(false)
                        }} className={'btn'}>Закрыть</div>
                    </div>
            }
            </>
    ):
        (
            <Loader/>
        )
};

export default GameConstructor;